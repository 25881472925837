.modal-agree {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
.modal-wrap {
  width: 100%;
  height: 100vh;
  position: relative;
}
.bg-black {
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1038;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 720px;
  height: auto;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 1.5rem;
  z-index: 1039;
}
.modal-box h5 {
  margin-top: 0px;
  margin-bottom: 16px;
  color: #323232;
  font-size: 1rem !important;
}
.modal-box ol {
  padding: 1.5rem;
  padding-inline-start: 2.5rem;
  background-color: #f2f2f2;
  border-radius: 1rem;
  margin-bottom: 16px;
}
.modal-box ol li {
  font-size: 0.85em;
  line-height: 1.2rem;
  color: #666;
  margin-bottom: 1rem;
}
.modal-box ol li:last-child {
  margin-bottom: 0;
}
.agree-btn-wrap {
  display: flex;
  justify-content: center;
}
.agree-btn-wrap button {
  width: 180px;
  padding: 0.8rem 0;
  border: 0;
  color: #fff;
  font-size: 0.9rem;
  border-radius: 2rem;
}
.agree-btn-wrap .disagree {
  margin-right: 10px;
  background-color: #b1b1b1;
}
.agree-btn-wrap .agree {
  background-color: #396C5A;
}

.agree-ol ol {
  padding: 5px 20px;
}
.agree-ol li {
  font-size: 0.9rem;
}
.agree-span {
  margin: 0.3px 0 -17px;
}
.agree-ol {
  width: 100%;
  height: 300px;
  background-color: #f6f6f6;
}
